<template>
  <backoffice-container>
    <b-card v-if="!isLoading && form" class="px-1 pb-2">
      <div class="d-flex justify-content-between flex-wrap my-1 mb-2">
        <h4>{{ $t('auth.subtitle') }}</h4>
        <b-button
          v-b-modal.new-role
          variant="primary"
          :class="{'ml-1' : !isSmall}"
          :block="isSmall"
          @click="editImage"
        >
          {{ $t('form.actions.save') }}
        </b-button>
      </div>
      <div>
        <b-row class="">
          <b-col cols="12" md="4" class="mt-2 " >
            <b-form-group label-for="image" class=" d-inline-block autentication-image" style="max-width: 500px;">
              <file-preview
                v-if="!form.imageURL && !image"
                :src="currentImage || previewImage"
                :placeholder-image="previewImage"
                mime-type="image"
                class="mb-1"
              />
              <file-preview
                v-if="form.imageURL && currentImage"
                :src="getImageResource(form.imageURL)"
                :placeholder-image="previewImage"
                mime-type="image"
                cancelable
                class="mb-1"
                @cancel="form.imageURL = null"
              />
              <small class="text-muted font-weight-bold ml-25">
                <feather-icon
                  icon="InfoIcon"
                  size="14"
                />
                {{ $t('tooltips.recommended') }} 1224px x 915px</small>
              <file-upload
                v-model="image"
                type="image"
                cancelable
                class="mt-1"
                :placeholder="$t('form-create-item.image-button')"
                :drop-placeholder="$t('form-create-item.drop-placeholder')"
                @cancel="image = null"
              />
            </b-form-group>
          </b-col>
          <!-- Preview -->
          <b-col >
            <div class="preview my-1  d-none d-md-block mr-xl-50 align-self-start">
              <p class="preview--title">
                {{ $t("backoffice.classifiers.preview") }}
              </p>
              <div class="d-flex border">
                <!-- filePath -->
                <img :src="image ? filePath : form.imageURL ? form.imageURL : currentImage || previewImage" class="preview-images" />
                <img :src="previewForm" class="preview-images"/>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </backoffice-container>
</template>

<script>
import BackofficeContainer from '@/backoffice/components/BackofficeContainer.vue';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import { getImageResource } from '@/@core/utils/image-utils';
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue';
import FileUpload from '@core/components/files/FileUpload.vue';
import FilePreview from '@core/components/files/FilePreview.vue';
import MembersPlaceholder from '@/assets/images/placeholders/light/members.svg';
import authImage from '@/assets/images/pages/login/default_auth_image.svg';
import authForm from '@/assets/images/pages/login/auth_form.png';

export default {
  name: 'BackofficeOnboardingForm',
  components: {
    BackofficeContainer,
    FileUpload,
    FilePreview,
  },
  data() {
    return {
      isSmall: false,
      isLoading: false,
      selectedLanguage: this.currentLocale,
      selectedLanguageQuestions: '',
      languagesOptions: [],
      options: [{}, {}],
      name: { ca: '', es: '', en: '' },
      placeholder: { ca: '', es: '', en: '' },
      initialQuestion: { type: 'text' },
      initForm: {
        name: { ca: "Formulari d'inscripció", es: 'Formulario de inscripción', en: 'Onboarding form' },
        description: {},
        isPublished: false,
        isOnboarding: true,
        questions: [],
        imageSrc: null,
      },
      question: {},
      image: null,
      key: null,
      questionDelete: null,
      formDelete: null,
      isSending: false,
      dragging: false,
      description: {},
      isUnshareModalVisible: false,
      members: [],
      isDraggable: false,
      actualItems: [],
      firstItems: [],
      filePath: '',
      form: {},
    };
  },
  computed: {
    currentImage() {
      return this.$store.getters.authImage?.community_customizations?.auth_image_url != null ? this.$store.getters.authImage?.community_customizations?.auth_image_url['/login'].es : null;
    },
    previewBg() {
      return {
        "background-image": `url(${this.image ? this.filePath : this.previewImage})`,
        "max-width": "538px",
        "background-size": "cover",
        "background-repeat":"no-repeat",
        "background-position": "center center",
      };
    },
    previewImage() {
      return authImage;
    },
    previewForm() {
      return authForm;
    },
    membersPlaceholder() {
      return MembersPlaceholder;
    },
    fields() {
      return [
        {
          key: 'name',
          label: this.$t('backoffice.members.fullname'),
          sortable: true,
        },
      ];
    },
    locales() {
      return this.$store.getters.locales;
    },
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    collective() {
      return this.$store.getters.currentCollective;
    },
  },
  watch: {
    image(value) {
      this.filePath = URL.createObjectURL(value)
    },
  },
  async created() {
    this.isLoading = true;
    await this.getAuthData();
    this.selectedLanguage = this.currentLocale;
    this.isLoading = false;
    window.addEventListener('resize', this.handleResize);
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.isSmall = window.innerWidth < 500;
    },
    getImageResource(image) {
      return getImageResource(image);
    },
    translatedFieldName(field) {
      for (const row of Object.keys(field)) {
        if (field[row]?.length === 0 || !field[row]) {
          delete field[row];
        }
      }
      return translateTranslationTable(this.selectedLanguage, field);
    },
    async getAuthData() {
      await this.$store.dispatch('getItems', {
        itemType: 'CommunityCustomizations',
        customName: 'authImage',
        page: 1,
      });
    },
    async editImage() {
      const file = {
        '/login-es': this.image,
      };
      try {
        await this.$store.dispatch('editItem', {
          noSet: true,
          item: {
            itemType: 'communityCustomizations',
            // requestConfig: {
            //   auth_image_url: {
            //     '/login': {
            //       ca: this.image,
            //       en: this.image,
            //       es: this.image,
            //     },
            //   },
            // },
          },
          file,

        });
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: this.$t('success-message.general-success-edit'),
            icon: 'AlertTriangleIcon',
            variant: 'success',
          },
        });
      } catch {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: this.$t('error-message.general-error'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-include'; // Bootstrap includes

.vs__dropdown-toggle {
  padding: 3.5px 0 7px !important;
  min-width: 160px;
}
.number {
  height: 2.5rem;
  width: 2.5rem;
  min-width: 2.5rem;
}.grid-item {
  box-shadow: 0px 0px 6px -2px $primary !important;
}
.preview {
  border: 1px solid rgba(34, 41, 47, 0.125);
  border-radius: 1.2rem;
  padding: 2rem;
  position: relative;
  width: fit-content;
  &--classifier {
    padding: 0.3rem 0.5rem;
    width: fit-content;
    text-align: center;
    border-radius: 0.358rem;
  }
  &--title {
    margin-top: 0;
    margin-bottom: 1rem;
    position: absolute;
    top: -10px;
    left: 20px;
    padding: 0 0.5rem;
    background-color: #ffffff;
  }
}
.preview-images {
  max-height: 400px !important;
  @media (max-width: 899px) {
    max-height: 270px !important;
  }
  @media (min-width: 900px) and (max-width: 1190px)  {
    max-height: 200px !important;
  }
  @media (min-width: 1191px) and (max-width: 1405px)  {
    max-height: 200px !important;
  }
  @media (min-width: 1406px) and (max-width: 1659px) {
    max-height: 270px !important;
  }
  @media (min-width: 1660px) and (max-width: 1770px) {
    max-height: 320px !important;
  }
  @media (min-width: 1771px) and (max-width: 1825px) {
    max-height: 370px !important;
  }
}
.autentication-image {
  // @media (max-width: 1370px) {
  //   max-width: 250px !important;

  // }
  @media (max-width: 899px) {
    min-width: 280px !important;
  }
  @media (min-device-width: 900px) and (max-device-width: 1041px) {
    max-height: 200px !important;
  }
  @media (min-device-width: 1198px) and (max-device-width: 1336px) {
    max-width: 250px !important;;
  }
}
</style>
